/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useMemo} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import * as API from '../../../api'
import {sha256} from 'crypto-hash'

const initialValues = {
  email: '',
  password: '',
  code: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [stage, setStage] = useState('login')
  const [authData, setAuthData] = useState<any>(null)
  const [userData, setUserData] = useState<any>(null)

  const validationSchema = useMemo(() => {
    if (stage === 'login') {
      return Yup.object().shape({
        email: Yup.string()
          .email('Formato de e-mail incorreto')
          .min(6, 'Mínimo de 6 caracteres')
          .max(50, 'Máximo de 50 caracteres')
          .required('O Preenchimento do e-mail é necessário'),
        password: Yup.string()
          .min(6, 'Mínimo de 6 caracteres')
          .max(50, 'Máximo de 50 caracteres')
          .required('O Preenchimento da senha é necessário'),
      })
    } else if (stage === 'mfa') {
      return Yup.object().shape({
        code: Yup.string()
          .required('O código MFA é necessário')
          .length(6, 'O código MFA deve ter 6 dígitos'),
      })
    }
    return Yup.object().shape({})
  }, [stage])

  const formik = useFormik({
    initialValues,
    
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (stage === 'login') {
        try {
          const password = await sha256(values.password)
          const auth = await API.login(values.email, password)
          if (!auth?.success && auth.error == 'MAINTENANCE') {
            setStatus('Servidor em manutenção, tente novamente mais tarde')
            setSubmitting(false)
            setLoading(false)
            return
          } else if (!auth?.success && auth.error == 'PASSWORD_EXPIRED') {
            setStatus(
              'Sua senha expirou! Você receberá um e-mail com um link para a criação de uma nova senha.'
            )
            setSubmitting(false)
            setLoading(false)
            return
          } else if (!auth?.success) {
            setStatus('Dados de acesso incorretos')
            setSubmitting(false)
            setLoading(false)
            return
          } else {
            // Successful login
            const authData = auth.data;
            //saveAuth(authData);
            if (authData.mfa_enabled && authData.has_mfa_secret) {
              // MFA is required
              setAuthData(authData)
              //setUserData(user)
              setStage('mfa')
              setSubmitting(false)
              setLoading(false)
            } else {
              // Proceed as normal
              const {data: user} = await getUserByToken(authData.api_token)
              saveAuth(authData)
              setCurrentUser(user)
            }
          }
        } catch (error) {
          saveAuth(undefined)
          setStatus('Dados de acesso incorretos')
          setSubmitting(false)
          setLoading(false)
        }
      } else if (stage === 'mfa') {
        // Validate MFA code
        try {
          const response = await API.validateMFA({code:values.code, token:authData.api_token})
          console.log(response);
          if (response.success) {
            const {data: user} = await getUserByToken(response.data);
            authData.api_token = response.data;
            saveAuth(authData);
            setCurrentUser(user);
          } else {
            // MFA code is invalid
            setStatus('Código MFA inválido')
            setSubmitting(false)
            setLoading(false)
          }
        } catch (error) {
          // Handle error
          setStatus('Erro ao validar o código MFA')
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  return (
    <div>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Insight Experience</h1>
        </div>
        {/* end::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <></>
        )}

        {stage === 'login' && (
          <>
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
              <input
                placeholder='E-mail'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
                  {/* end::Label */}
                  {/* begin::Link */}
                  {
                    <Link
                      to='/auth/forgot-password'
                      className='link-primary fs-6 fw-bolder'
                      style={{marginLeft: '5px'}}
                    >
                      Esqueceu sua senha?
                    </Link>
                  }
                  {/* end::Link */}
                </div>
              </div>
              <input
                type='password'
                autoComplete='off'
                placeholder='Senha'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </>
        )}

        {stage === 'mfa' && (
          <>
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Código MFA</label>
              <input
                placeholder='Código MFA'
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.code && formik.errors.code},
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
                type='text'
                name='code'
                autoComplete='off'
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.code}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </>
        )}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            
          >
            {!loading && (
              <span className='indicator-label'>
                {stage === 'login' ? 'Acessar' : 'Validar MFA'}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde por favor...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  )
}