import { PageTitle } from '../../../_metronic/layout/core'
import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import Select from 'react-select';

const StyledData = styled.div`
    .table-container {
        overflow-y: auto;
        overflow-x: auto;
        max-height: 70vh;
        margin: 20px;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }
    
    thead th {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1000; /* Muito alto para garantir prioridade */
    }
  
    .first-col,
    .first-col-header {
        position: sticky;
        left: 0;
        background-color: white;
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
    }

    .filter-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        z-index: 1001;
    }

    .filter-radio {
        margin-right: 10px;
    }

    .filter-dropdown {
        position: relative;
    }

    .filter-dropdown-input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 150px;
        margin-right: 5px;
    }

    .filter-dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        display: none;
    }

    .filter-dropdown-option {
        padding: 5px 10px;
        cursor: pointer;
    }

    .filter-dropdown-option:hover {
        background-color: #f6f6f6;
    }

    .filter-dropdown:hover .filter-dropdown-options {
        display: block;
    }

    .filter-dropdown-selected {
        color: #333;
    }
`;

const FeatureMatrix: FC = () => {
    const [menu, setMenu] = useState<any>();
    const [filterMenu, setFilterMenu] = useState<any>();
    const [selectedFilter, setSelectedFilter] = useState('Todos');
    const [dropdownValue, setDropdownValue] = useState('');
    const [selectedDropdownValues, setSelectedDropdownValues] = useState<any[]>([]);
    const [selectedCompanys, setSelectedCompanys] = useState<any[]>([]);
    const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
    
    const [companys, setCompanys] = useState<any[]>([]);
    const [companysData, setCompanysData] = useState<any[]>([]);

    const getFeatures = async () => {
        const result: any = await API.getFeatures();
        setMenu(result.data);
        setFilterMenu(result.data);
        setFilteredCompanies(result.data.companys);
        
        const data:any[] = [];
        result.data.companys.map((item: any) => {
            const obj: { [key: string]: any; value: any; label: any } = {
                value: item.guid,
                label: item.name
            };
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    obj[key] = item[key];
                }
            }
            data.push(obj);
        });
        setCompanys(data);
    };

    async function featurePermissionClick(id_feature: number, id_company: number, checked: boolean) {
        if (checked) {
            await API.addFeatureCompanyPermission({ id_feature, id_company });
            menu.permissions.push({guid:Math.random(), id_company, id_feature});
        } else {
            await API.removeFeatureCompanyPermission({ id_feature, id_company });
            menu.permissions.forEach((permission:any, index:number) => {
                if (permission.id_feature === id_feature && permission.id_company) {
                    menu.permissions.splice(index, 1);
                    return;
                }
            });
        }
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilter(event.target.value);
    };

    useEffect(() => {
        getFeatures();
    }, []);

    useEffect(() => {
        if (selectedCompanys.length) {
            setCompanysData(selectedCompanys);
        } else {
            if (menu) setCompanysData(menu.companys);
        }
    }, [selectedCompanys, menu?.companys]);

    useEffect(() => {
        const filteredData = menu?.companys?.filter((company:any) => {
            const filterMatch = selectedFilter === 'Todos' || company.id_type_company == selectedFilter;
            const dropdownMatch = selectedCompanys.length === 0 || selectedCompanys.find((c) => c.guid == company.guid);
            return filterMatch && dropdownMatch;
        });
        setFilteredCompanies(filteredData);
    }, [selectedFilter, selectedCompanys, menu]);

    return (
    <StyledData>
        <PageTitle>Funcionalidades</PageTitle>
        <div key={`features`} className="">
            <div className="table-responsive">
                <div className='ms-1 w-600px'>                    
                    <div className="filter-group d-flex justify-content-between">
                        <label>Tipo de Empresa:</label>
                        <label htmlFor="filter-radio-todos" className='me-5'>
                            <input type="radio" id="filter-radio-todos" name="filter-radio" value="Todos" checked={selectedFilter === 'Todos'} onChange={handleFilterChange} className="filter-radio"/>
                            Todos
                        </label>
                        <label htmlFor="filter-radio-industrias" className='me-5'>
                            <input type="radio" id="filter-radio-industrias" name="filter-radio" value="1" checked={selectedFilter === '1'} onChange={handleFilterChange} className="filter-radio"/>
                            Indústrias
                        </label>
                        <label htmlFor="filter-radio-distribuidores" className='me-5'>
                            <input type="radio" id="filter-radio-distribuidores" name="filter-radio" value="2" checked={selectedFilter === '2'} onChange={handleFilterChange} className="filter-radio"/>
                            Distribuidores
                        </label>   
                    </div>
                </div>
                <div className="filter-group">
                    <div className='form-group mb-4 w-100'>
                        <Select
                            isMulti
                            options={companys}
                            value={selectedCompanys}
                            closeMenuOnSelect={false}
                            placeholder="Selecionar empresas"
                            onChange={(selected: any) => setSelectedCompanys(selected)}
                            className="multi-select"
                        />
                    </div>
                </div>
                <div className="table-wrapper">
                    <div className="table-container">
                        <table className="table table-row-dashed table-row-gray-300 align-middle justify-content-center">
                        <thead>
                            <tr className="fw-bold text-muted">
                                <th className="w-200px first-col first-col-header">Empresa (A-Z)</th>
                                {menu?.features?.map((feature: any) => (
                                    <React.Fragment key={`c_p_${feature.id_feature}`}>
                                        <th key={`title_product_${feature.id_product}`} className="justify-content-center text-center">
                                            <button className="btn btn-active-light-primary" onClick={() => { /* Feature click handler */ }}>
                                                {feature.title}
                                            </button>
                                        </th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCompanies?.sort((a, b) => (a.name < b.name ? -1 : 1)).map((company: any) => (
                                <tr key={`${company.id_company}`} className="justify-content-left">
                                    <td className="first-col w-200px">
                                        <span className="text-muted fw-semibold text-muted d-block fs-7">
                                        {company.name}
                                        </span>
                                    </td>
                                    {menu.features.map((feature: any) => (
                                    <React.Fragment key={`cc_p_${feature.id_product}_${feature.id_feature}`}>
                                        <td key={Math.random()} className="justify-content-center align-middle">
                                            <div className="form-check form-check-sm form-check-custom d-flex justify-content-center text-center">
                                                <input
                                                    className="form-check-input widget-9-check"
                                                    type="checkbox"
                                                    defaultChecked={menu.permissions.find((p: any) => p.id_feature === feature.id_feature && p.id_company === company.id_company)}
                                                    onChange={(evt) => featurePermissionClick(feature.id_feature, company.id_company, evt.target.checked)}
                                                />
                                            </div>
                                        </td>
                                    </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>           
            </div>
        </div>
    </StyledData>
    );
};

export default FeatureMatrix;