import { PageTitle } from '../../../_metronic/layout/core'
import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {KTSVG } from '../../../_metronic/helpers'
import * as API from '../../api';
import {Modal} from 'react-bootstrap';
import CompanyModal from './CompanyModal';
import ModalConfirm from "./../dashboard/ModalConfirm";
import BtnEdit from "../../components/BtnEdit";
import BtnTrash from '../../components/BtnTrash';
import Select from 'react-select';

const Styled = styled.div`
    
    .remove-user-title {
        margin:20px !important;
    }

    svg {
        max-width: 20px;
        opacity: 0.7;
    }    
`;

const domainPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

const CompanyConfig:FC = () => {
    const [loading, setLoading] = useState(false)
    
    const [currentDomain, setCurrentDomain] = useState<string>('');
    const [domain, setDomain] = useState<string>('');
    


    function validationStatus() {
        if (domain.length > 0 && !domainPattern.test(domain)) {
            return 'Nome da empresa deve conter no mínimo 2 caracteres e ser um domínio válido';
        }
        return '';
    }

    async function onSubmit() {
        setLoading(true);
        try {
            await API.updateDomain(domain);
            reload();
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    async function getData() {
        const response = await API.getCompanyDomain();
        setCurrentDomain(response.data.domain);
        setDomain(response.data.domain);
    }

    function reload() {
        window.location.href = window.location.href;
    }

    useEffect(() => {
        getData();
    }, []);

    return <Styled>
        <PageTitle>Configurações</PageTitle>
        <div className={`card`}>
            <div className='form-item'>
                <p>Dominio: </p>
                <input type='text' className='form-control form-control-lg form-control-solid' name='name' placeholder='Dominio (Exemplo: nomedaempresa.com.br)' value={domain} onChange={(e) =>
                    setDomain(e.currentTarget.value)
                }/>
                
            </div>
            <div className='text-center'>
                <button id='' className='btn btn-lg btn-primary w-25 mt-5' disabled={validationStatus() != '' || loading || domain == currentDomain} onClick={() => { onSubmit() }}>
                {!loading && <span className='indicator-label'>Atualizar</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                        Aguarde por favor...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
            </div>
        </div>
    </Styled>

}

export default CompanyConfig;

